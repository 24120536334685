import React, { Component } from 'react';
import IrisPortalService from '../../services/IrisPortalService';
import Utils from '../../utils/utils';
import { Button } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { PageContainer } from '../../components/common/blocks/PageContainer';
import { Paper } from '../../components/home/Paper';
import { Loader } from '../../components/common/blocks/Loader';

import { ColorPicker } from 'material-ui-color';
import { CopyBlock, dracula } from 'react-code-blocks';
import _ from 'lodash';
import { logEventWithUserInfo } from '../../utils/amplitude';

import './ManageChatbot.scss';
import { Customer } from '../../services/types';
import { QueryObserverResult } from 'react-query';
import { ReactComponent as HelpSvg } from '../../assets/Help.svg';
import { DarkTooltip } from '../incidents/incident-list/DarkTooltip';

// UseCustomerQuery is a utility component that encapsulates the logic to use React Query inside a React class component
// It is used to fetch the customer data from the backend
function UseCustomerQuery(props: any) {
  return props.children(Utils.useCustomerQuery());
}

class ManageChatbot extends Component<RouteComponentProps, any> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      isLoadingList: true,
      externalIdentifier: null,
      // brandLogo: {
      //   preview: "https://storage.googleapis.com/ia-public/chatbot-images/default_chatbot_brand_logo_irisagent_logo.svg",
      //   raw: ''
      // },
      // bannerColor: '',
      noSavedSetting: false,
      brandLogoImageSelected: false
    };
  }

  getCodeSnippet = (externalIdentifier: string) => {
    return (
      '  <script src="https://frontend-api-server-v2.api.irisagent.com/v1/chatbot/bundlejs"></script>\n' +
      "  <script type='text/javascript'>\n" +
      '    initIrisAgentChatbot( "' +
      externalIdentifier +
      '" );\n' +
      '  </script>'
    );
  };

  async componentDidMount() {
    const response = await IrisPortalService.getChatbotSetting();
    const { chatbotSetting, externalIdentifier } = response;

    if (_.isNil(chatbotSetting)) {
      this.setState({
        isLoadingList: false,
        noSavedSetting: true,
        externalIdentifier: externalIdentifier
      });
      return;
    } else {
      let stateUpdateDict = {
        isLoadingList: false,
        bannerColor: chatbotSetting.bannerColor,
        brandLogo: {
          preview: chatbotSetting.brandLogo,
          raw: chatbotSetting.brandLogo
        },
        brandLogoImageSelected: !!chatbotSetting.brandLogo,
        externalIdentifier: externalIdentifier
      };

      this.setState(stateUpdateDict);
    }
  }

  handlebrandLogoChange = (e: any) => {
    if (e.target.files.length) {
      this.setState({
        brandLogoImageSelected: true,
        brandLogo: {
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0]
        }
      });
    }
  };

  async saveChanges(e: any) {
    if (this.state.noSavedSetting) {
      let result = await IrisPortalService.createChatbotSetting(
        this.state.brandLogo?.raw,
        this.state.bannerColor
      );
      console.log('createChatbotSetting result', result);
    } else {
      let updateDict: any = {};
      if (this.state.brandLogo?.raw) {
        updateDict['brandLogo'] = this.state.brandLogo.raw;
      }
      if (this.state.bannerColor) {
        updateDict['bannerColor'] = this.state.bannerColor;
      }
      let result = await IrisPortalService.editChatbotSetting(updateDict);
      console.log('editChatbotSetting result', result);
    }
    window.location.reload();
    Utils.showNotify('Saved successfully');
  }

  render() {
    return (
      <Paper padding={'0'}>
        {this.state.isLoadingList ? (
          <Loader center />
        ) : (
          <>
            <PageContainer
              style={{
                textAlign: 'left'
              }}
            >
              <UseCustomerQuery>
                {({
                  isLoading,
                  data: customer
                }: QueryObserverResult<Customer, any>) => {
                  if (isLoading || customer?.hideChatbotPricing) {
                    return '';
                  }

                  return (
                    <React.Fragment>
                      <h2>Get started for free!</h2>
                      <Paper>
                        <h3>Chatbot pricing</h3>
                        <br />
                        <ul style={{ fontWeight: 'normal', paddingLeft: '2%' }}>
                          <li>
                            <b>First 100 chat sessions free every month</b>:
                            Test drive our chatbot at no cost.
                          </li>
                          <li>
                            <b>Predictive pricing</b>: Just $150 per month after
                            you exhaust the free 100 sessions. Includes 500
                            additional chat sessions per month.
                          </li>
                          <li>
                            <b>Interested in add-ons?</b>{' '}
                            <a
                              rel="noopener noreferrer"
                              style={{ color: 'blue' }}
                              href="https://calendly.com/palak-iris/irisagent-setup"
                              target="_blank"
                            >
                              Book a short meeting
                            </a>
                            &nbsp;to subscribe to our popular add-ons (seamless
                            agent handoff,{' '}
                            <a
                              rel="noopener noreferrer"
                              style={{ color: 'blue' }}
                              href="https://irisagent.com/support-agent-assist/"
                              target="_blank"
                            >
                              agent assist
                            </a>
                            , &nbsp;
                            <a
                              rel="noopener noreferrer"
                              style={{ color: 'blue' }}
                              href="https://irisagent.com/support-operations/"
                              target="_blank"
                            >
                              automated tagging
                            </a>
                            , custom branding and more!)
                          </li>
                        </ul>
                      </Paper>
                    </React.Fragment>
                  );
                }}
              </UseCustomerQuery>

              <h2>
                Embed the IrisGPT Chatbot on your site &nbsp;
                <DarkTooltip title="This is a premium feature. Please contact us for pricing and activation.">
                  <HelpSvg />
                </DarkTooltip>
              </h2>
              <Paper>
                <h3>
                  Add the code below in the "body" section of your website (
                  <a
                    rel="noopener noreferrer"
                    href="https://irisagent.com/irisgpt-chatbot-zendesk-help-center/"
                    target="_blank"
                    style={{ color: 'blue' }}
                  >
                    Zendesk setup instructions
                  </a>
                  )
                </h3>
                <br />
                <CopyBlock
                  text={this.getCodeSnippet(this.state.externalIdentifier)}
                  language="html"
                  theme={{
                    ...dracula,
                    mode: 'light'
                  }}
                  showLineNumbers={false}
                  startingLineNumber={1}
                  wrapLongLines={true}
                  codeBlock={
                    // If true, the component adds user-select: none to all the children which makes the entire
                    // element unselectable and unable to copy
                    false
                  }
                  copied={false}
                  onCopy={() => {
                    Utils.showNotify('Copied to clipboard');
                    logEventWithUserInfo(
                      `Clicked Chatbot Code Snippet Copy Button`,
                      {
                        externalIdentifier: this.state.externalIdentifier
                      }
                    );
                  }}
                />
                <br />
                <h3>Add your branding</h3>
                <br />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <h4 style={{ fontWeight: 'normal' }}>Banner Color: </h4>
                  <ColorPicker
                    value={this.state.bannerColor}
                    disableTextfield={false}
                    onChange={(color) => {
                      this.setState({ bannerColor: `#${color.hex}` });
                    }}
                  />
                </div>
                {this.state.brandLogoImageSelected ? (
                  <div style={{ fontWeight: 'normal' }}>
                    <h4 style={{ fontWeight: 'normal' }}>
                      Last selected brand logo:
                    </h4>
                    <img
                      src={this.state.brandLogo.preview}
                      alt="dummy"
                      width="100"
                      height="100"
                    />
                    <br />
                    <Button
                      // className="button_details"
                      style={{
                        textTransform: 'none',
                        fontSize: '10px'
                      }}
                      variant="outlined"
                      onClick={() => {
                        this.setState({
                          brandLogoImageSelected: false
                        });
                      }}
                    >
                      {'Remove Selection'}
                    </Button>
                  </div>
                ) : (
                  <div>
                    <h4 style={{ fontWeight: 'normal' }}>Brand Logo: </h4>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={this.handlebrandLogoChange}
                      onClick={(event) => {
                        (event.target as HTMLInputElement).value = '';
                      }}
                    />
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'right'
                  }}
                >
                  <Button
                    className="save_settings_button_details"
                    variant="contained"
                    onClick={this.saveChanges.bind(this)}
                  >
                    <b>Save Settings</b>
                  </Button>
                </div>
              </Paper>
              <h2>Finish Setting up your Account</h2>
              <Paper>
                <ul style={{ fontWeight: 'normal', paddingLeft: '2%' }}>
                  <li>
                    <b>Install Agent Assist app</b>: Install our app for Agent
                    Assist in your ticketing system so that your agents don't
                    have to switch tabs. Install the app for{' '}
                    <a
                      rel="noopener noreferrer"
                      style={{ color: 'blue' }}
                      href="https://www.zendesk.com/marketplace/apps/support/244660/irisagent/"
                      target="_blank"
                    >
                      Zendesk
                    </a>
                    ,{' '}
                    <a
                      rel="noopener noreferrer"
                      style={{ color: 'blue' }}
                      href="https://www.intercom.com/app-store/?app_package_code=iris&search=irisagent"
                      target="_blank"
                    >
                      Intercom
                    </a>
                    ,{' '}
                    <a
                      rel="noopener noreferrer"
                      style={{ color: 'blue' }}
                      href="https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000FZ4X6UAL"
                      target="_blank"
                    >
                      Salesforce
                    </a>
                    , or{' '}
                    <a
                      rel="noopener noreferrer"
                      style={{ color: 'blue' }}
                      href="https://www.freshworks.com/apps/irisagent"
                      target="_blank"
                    >
                      Freshworks
                    </a>
                    .
                  </li>
                  <li>
                    <b>Set up automated ticket tagging</b>: Follow{' '}
                    <a
                      rel="noopener noreferrer"
                      style={{ color: 'blue' }}
                      href="https://irisagent.com/automation-docs/"
                      target="_blank"
                    >
                      this short guide
                    </a>{' '}
                    to set up automated tagging and get rid of manual and
                    inaccurate tagging.
                  </li>
                </ul>
              </Paper>
              <br />
              <br />
            </PageContainer>
          </>
        )}
      </Paper>
    );
  }
}

export default ManageChatbot;
