import React from 'react';
import './Tool.scss';
import { Tool } from './Tool';
import { ReactComponent as Tag } from '../../../../assets/tag.svg';
import { ReactComponent as AccountName } from '../../../../assets/AccountName.svg';
import { ReactComponent as CustomField } from '../../../../assets/CustomField.svg';
import { ReactComponent as FileMinus } from '../../../../assets/fileMinus.svg';
import { ReactComponent as BookOpen } from '../../../../assets/bookOpen.svg';
import { ReactComponent as Category } from '../../../../assets/Category.svg';
import { ReactComponent as Condition } from '../../../../assets/gitMerge.svg';

import { Option } from '../../types';
import {
  CategoryAccountName,
  CategoryCategories,
  CategoryCustomField,
  CategorySubject,
  CategoryTags,
  CategoryTicketContent,
  CategoryTicketPriority,
  DropboxHasActiveSubscription,
  DropboxHasMultipleDevicesConnected,
  DropboxHasPremiumSupport,
  DropboxIsEligibleForRefund
} from '../../constants';

type Props = {
  conditionToolClicked: (condition: string) => void;
  toolItems: Option[];
};

export const ConditionTool: React.FC<Props> = (props) => {
  const headerIcon = () => {
    return <Condition stroke="#F2F2F2" />;
  };
  const getIcon = (iconName: string) => {
    switch (iconName) {
      case CategoryTags:
        return <Tag />;
      case CategoryTicketPriority:
        return <Tag />;
      case CategoryCustomField:
        return <CustomField />;
      case CategorySubject:
        return <BookOpen />;
      case CategoryTicketContent:
        return <FileMinus />;
      case CategoryAccountName:
        return <AccountName />;
      case CategoryCategories:
        return <Category />;
      case DropboxHasActiveSubscription:
        return <CustomField />;
      case DropboxHasMultipleDevicesConnected:
        return <CustomField />;
      case DropboxHasPremiumSupport:
        return <CustomField />;
      case DropboxIsEligibleForRefund:
        return <CustomField />;
      default:
        return;
    }
  };

  return (
    <Tool
      headerColor="#5D5FEF"
      heading="Conditions"
      toolItems={props.toolItems}
      getIcon={getIcon}
      headerIcon={headerIcon}
      conditionToolClicked={props.conditionToolClicked}
    ></Tool>
  );
};
